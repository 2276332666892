const mpublic = {

  bigs(arr) { //从大到小排序
    var max;
    //遍历数组，默认arr中的某一个元素为最大值，进行逐一比较
    for (var i = 0; i < arr.length; i++) {
      //外层循环一次，就拿arr[i] 和 内层循环arr.legend次的 arr[j] 做对比
      for (var j = i; j < arr.length; j++) {
        if (arr[i] < arr[j]) {
          //如果arr[j]大就把此时的值赋值给最大值变量max
          max = arr[j];
          arr[j] = arr[i];
          arr[i] = max;
        }
      }
    }
    return arr
  },

  smallb(arr) {//从小到大排序
    var max;
    //遍历数组，默认arr中的某一个元素为最大值，进行逐一比较
    for (var i = 0; i < arr.length; i++) {
      //外层循环一次，就拿arr[i] 和 内层循环arr.legend次的 arr[j] 做对比
      for (var j = i; j < arr.length; j++) {
        if (arr[i] > arr[j]) {
          //如果arr[j]大就把此时的值赋值给最大值变量max
          max = arr[j];
          arr[j] = arr[i];
          arr[i] = max;
        }
      }
    }
    return arr
  },

  sum(arr) {//数组求和
    var s = 0;
    for (var i = arr.length - 1; i >= 0; i--) {
      s += arr[i];
    }
    return s;
  },

  tail(arr) {//数组尾数、含去重
    var xx = new Array();
    for (var i = 0; i < arr.length; i++) {
      if (xx.indexOf(arr[i] % 10) == -1) {
        xx.push(arr[i] % 10);
      }
    }
    return xx
  },

  max(arr) {//最大值
    var max = arr[0];
    for (var i = 0; i < arr.length; i++) {
      if (max < arr[i]) {
        max = arr[i];
      }
    }
    return max;
  },

  min(arr) {//最小值
    var min = arr[0];
    for (var i = 0; i < arr.length; i++) {
      if (min > arr[i]) {
        min = arr[i];
      }
    }
    return min;
  },

  same(arr1, arr2) {//返回两个数组的相同元素
    return arr1.filter((v) => {
      return arr2.includes(v);
    });
  },

  isPrime(n) {//计算质数
    // var count = 0;
    var xx = []
    for (var i = 2; i <= n; i++) {
      var flag = true;
      for (var j = 2; j < i; j++) {
        if (i % j === 0) {
          flag = false
        }
      }
      if (flag) {
        xx.push(i)
      }
      else {
        i
        //console.log(i + '不是质数')
      }
    }
    return xx
  },

  ysq(arr) {//147
    var aa = []
    for (var i = 0; i <= arr; i++) {
      if (i % 10 == 1 || i % 10 == 4 || i % 10 == 7) {
        aa.push(i)
      }
    }
    return aa
  },

  ewb(arr) {//258
    var aa = []
    for (var i = 0; i <= arr; i++) {
      if (i % 10 == 2 || i % 10 == 5 || i % 10 == 8) {
        aa.push(i)
      }
    }
    return aa
  },

  slj(arr) {//369
    var aa = []
    for (var i = 1; i <= arr; i++) {
      if (i % 10 == 0 || i % 10 == 3 || i % 10 == 6 || i % 10 == 9) {
        aa.push(i)
      }
    }
    return aa
  },

  del() {//删除对话框
    var msg = "您确定要删除吗？";
    if (confirm(msg) == true) {
      return true;
    } else {
      return false;
    }
  },
  fu(xuan, wanfa, xh, xl, zh, zl) {
    var arr = []
    var x = zh
    var x0 = zh
    var y = zh
    var z = xuan
    var obj = {}
    if (wanfa == "ssq") {
      if (zl == 0) {
        if (zh < 4) {
          return 0
        } else {
          while (x > 3) {
            obj[x + "0"] = (this.mon(zh - x + 1, zh, x, 1) / 2) * (this.mon((xh - zh) - (6 - x) + 1, xh - zh, 6 - x, 1) / 2) * xl
            x--
          }
          //arr.push(obj)
        }

      } else if (zl == 1) {
        while (x0 > -1) {
          obj[x0 + "1"] = (this.mon(zh - x0 + 1, zh, x0, 1) / 2) * (this.mon((xh - zh) - (6 - x0) + 1, xh - zh, 6 - x0, 1) / 2)
          x0--
        }
        if (xl > 1) {
          while (y > 3) {
            obj[y + "0"] = (this.mon(zh - y + 1, zh, y, 1) / 2) * (this.mon((xh - zh) - (6 - y) + 1, xh - zh, 6 - y, 1) / 2) * (xl - 1)
            y--
          }
        }
        //arr.push(obj)
      }
      arr.push(obj)
    } else if (wanfa == "qlc") {
      if (zl == 0) {
        if (zh <= 3) {
          return 0
        } else {
          while (x > 3) {
            obj[x+"0"] = (this.mon(zh - x + 1, zh, x, 1) / 2) * (this.mon((xh - zh) - (7 - x) + 1, xh - zh, 7 - x, 1) / 2)
            x--
          }
          arr.push(obj)
        }

      } else {
        while (x > 3) {
          obj[x + "1"] = (this.mon(zh - x + 1, zh, x, 1) / 2) * (this.mon((xh - zh) - (7 - x) + 1, xh - zh - 1, 7 - x - 1, 1) / 2)
          x--
        }
        while (y > 3) {
          obj[y+"0"] = (this.mon(zh - y + 1, zh, y, 1) / 2) * (this.mon((xh - zh - 1) - (7 - y) + 1, xh - zh - 1, 7 - y, 1) / 2)
          y--
        }
        arr.push(obj)
        // arr1.push(obj1)
      }
    } else if (wanfa == "klb") {
      if (xuan == 1 && zh > 0) {
        obj[1] = zh
      } else if (xuan == 2 && zh > 1) {
        while (x >= 2) {
          obj[xuan] = this.mon(zh - x + 1, zh, xuan, 1) / 2
          x--
        }
      } else if (xuan == 3 && zh > 1) {
        while (xuan >= 2) {
          obj[xuan] = (this.mon(zh - xuan + 1, zh, xuan, 1) / 2) * ((this.mon((xh - zh) - (z - xuan) + 1, xh - zh, z - xuan, 1) / 2) == 0 ? 1 : (this.mon((xh - zh) - (z - xuan) + 1, xh - zh, z - xuan, 1) / 2))
          xuan--
        }
      } else if (xuan == 4 && zh > 1) {
        while (xuan >= 2) {
          obj[xuan] = (this.mon(zh - xuan + 1, zh, xuan, 1) / 2) * ((this.mon((xh - zh) - (z - xuan) + 1, xh - zh, z - xuan, 1) / 2) == 0 ? 1 : (this.mon((xh - zh) - (z - xuan) + 1, xh - zh, z - xuan, 1) / 2))
          xuan--
        }
      } else if (xuan == 5 && zh > 2) {
        while (xuan >= 3) {
          obj[xuan] = (this.mon(zh - xuan + 1, zh, xuan, 1) / 2) * ((this.mon((xh - zh) - (z - xuan) + 1, xh - zh, z - xuan, 1) / 2) == 0 ? 1 : (this.mon((xh - zh) - (z - xuan) + 1, xh - zh, z - xuan, 1) / 2))
          xuan--
        }
      } else if (xuan == 6 && zh > 2) {
        while (xuan >= 3) {
          obj[xuan] = (this.mon(zh - xuan + 1, zh, xuan, 1) / 2) * ((this.mon((xh - zh) - (z - xuan) + 1, xh - zh, z - xuan, 1) / 2) == 0 ? 1 : (this.mon((xh - zh) - (z - xuan) + 1, xh - zh, z - xuan, 1) / 2))
          xuan--
        }
      } else if (xuan == 7) {
        if (zh > 3) {
          while (xuan >= 4) {
            obj[xuan] = (this.mon(zh - xuan + 1, zh, xuan, 1) / 2) * ((this.mon((xh - zh) - (z - xuan) + 1, xh - zh, z - xuan, 1) / 2) == 0 ? 1 : (this.mon((xh - zh) - (z - xuan) + 1, xh - zh, z - xuan, 1) / 2))
            xuan--
          }
        } else if (xh - zh >= 7) {
          obj[0] = (this.mon(zh - 0 + 1, zh, 0, 1) / 2) * ((this.mon((xh - zh) - (z - 0) + 1, xh - zh, z - 0, 1) / 2) == 0 ? 1 : (this.mon((xh - zh) - (z - 0) + 1, xh - zh, z - 0, 1) / 2))
        }

      } else if (xuan == 8) {
        if (zh > 3) {
          while (xuan >= 4) {
            obj[xuan] = (this.mon(zh - xuan + 1, zh, xuan, 1) / 2) * ((this.mon((xh - zh) - (z - xuan) + 1, xh - zh, z - xuan, 1) / 2) == 0 ? 1 : (this.mon((xh - zh) - (z - xuan) + 1, xh - zh, z - xuan, 1) / 2))
            xuan--
          }
        } else if (xh - zh >= 8) {
          obj[0] = (this.mon(zh - 0 + 1, zh, 0, 1) / 2) * ((this.mon((xh - zh) - (z - 0) + 1, xh - zh, z - 0, 1) / 2) == 0 ? 1 : (this.mon((xh - zh) - (z - 0) + 1, xh - zh, z - 0, 1) / 2))
        }

      } else if (xuan == 9) {
        if (zh > 3) {
          while (xuan >= 4) {
            obj[xuan] = (this.mon(zh - xuan + 1, zh, xuan, 1) / 2) * ((this.mon((xh - zh) - (z - xuan) + 1, xh - zh, z - xuan, 1) / 2) == 0 ? 1 : (this.mon((xh - zh) - (z - xuan) + 1, xh - zh, z - xuan, 1) / 2))
            xuan--
          }
        } else if (xh - zh >= 9) {
          obj[0] = (this.mon(zh - 0 + 1, zh, 0, 1) / 2) * ((this.mon((xh - zh) - (z - 0) + 1, xh - zh, z - 0, 1) / 2) == 0 ? 1 : (this.mon((xh - zh) - (z - 0) + 1, xh - zh, z - 0, 1) / 2))
        }
      } else if (xuan == 10) {
        if (zh > 4) {
          while (xuan >= 5) {
            obj[xuan] = (this.mon(zh - xuan + 1, zh, xuan, 1) / 2) * ((this.mon((xh - zh) - (z - xuan) + 1, xh - zh, z - xuan, 1) / 2) == 0 ? 1 : (this.mon((xh - zh) - (z - xuan) + 1, xh - zh, z - xuan, 1) / 2))
            xuan--
          }
        } else if (xh - zh >= 10) {
          obj[0] = (this.mon(zh - 0 + 1, zh, 0, 1) / 2) * ((this.mon((xh - zh) - (z - 0) + 1, xh - zh, z - 0, 1) / 2) == 0 ? 1 : (this.mon((xh - zh) - (z - 0) + 1, xh - zh, z - 0, 1) / 2))
        }
      } else {
        return 0
      }
      arr.push(obj)

    } else if (wanfa == "dlt") {
      if (zl == 0) {
        if (zh < 3) {
          return 0
        } else {
          while (x >= 3) {
            obj[x+"0"] = (this.mon(zh - x + 1, zh, x, 1) / 2) * (this.mon((xh - zh) - (5 - x) + 1, xh - zh, 5 - x, 1) / 2) * (this.mon(xl - 2 + 1, xl, 2, 1) / 2)
            x--
          }
          arr.push(obj)
        }

      } else if (zl == 1) {
        if (zh < 2) {
          return 0
        } else {
          while (x > 1) {
            obj[x + "1"] = (this.mon(zh - x + 1, zh, x, 1) / 2) * (this.mon((xh - zh) - (5 - x) + 1, xh - zh, 5 - x, 1) / 2) * (xl - 1)
            x--
          }
          //((this.mon((xl - zl) - 2 + 1, xl - zl, 2, 1) / 2) == 0 ? 1 : (this.mon((xl - zl) - 2 + 1, xl - zl, 2, 1) / 2))
          while (y > 2) {
            if (xl > 2) {
              obj[y+"0"] = (this.mon(zh - y + 1, zh, y, 1) / 2) * (this.mon((xh - zh) - (5 - y) + 1, xh - zh, 5 - y, 1) / 2) * ((this.mon((xl - zl) - 2 + 1, xl - zl, 2, 1) / 2) == 0 ? 1 : (this.mon((xl - zl) - 2 + 1, xl - zl, 2, 1) / 2))
            }
            y--
          }
          arr.push(obj)
        }

      } else if (zl == 2) {
        while (x >= 0) {
          obj[x + "2"] = (this.mon(zh - x + 1, zh, x, 1) / 2) * (this.mon((xh - zh) - (5 - x) + 1, xh - zh, 5 - x, 1) / 2)
          x--
        }
        while (x0 > 1) {
          obj[x0 + "1"] = (this.mon(zh - x0 + 1, zh, x0, 1) / 2) * (this.mon((xh - zh) - (5 - x0) + 1, xh - zh, 5 - x0, 1) / 2) * ((xl - zl) * zl)
          x0--
          // console.log("1:"+((this.mon((xl - zl) - 2 + 1, xl - zl, 2, 1) / 2)*2))
        }
        while (y > 2) {
          if (xl > 3) {
            obj[y+"0"] = (this.mon(zh - y + 1, zh, y, 1) / 2) * (this.mon((xh - zh) - (5 - y) + 1, xh - zh, 5 - y, 1) / 2) * ((this.mon((xl - zl) - 2 + 1, xl - zl, 2, 1) / 2) == 0 ? 1 : (this.mon((xl - zl) - 2 + 1, xl - zl, 2, 1) / 2))
          }
          y--
        }
        arr.push(obj)
      }

    }
    // console.log(arr)
    return arr
  },
  dan(xuan, tx, dl, wanfa, xd, xt, xl, zd, zt, zl) {
    var arr = []
    var zt1 = zt
    var zt2 = zt
    var zt3 = zt
    var ca = 6 - xd
    var ca1 = 6 - xd
    var ca2 = 6 - xd
    var kca = xuan - xd
    var kca1 = xuan - xd
    var qca = 7 - xd
    var qca1 = 7 - xd
    var q2ca = 7 - xd - 1
    var dca = 5 - xd
    var dca1 = 5 - xd
    var dca2 = 5 - xd
    var dca3 = 5 - xd
    // var z = xuan
    var obj = {}
    zt3, dca3
    if (wanfa == "ssq") {
      if (zl == 0) {
        if (zd + (6 - xd) < 4 || zd + zt < 4) {
          return 0
        } else {
          if (ca >= zt) {
            while (zt1 >= 0) {
              if (zd + zt1 > 3) {
                obj[parseInt(zd) + parseInt(zt1) + "0"] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((ca - zt1)) + 1, xt - zt, ca - zt1, 1) / 2) * xl
              }
              zt1--
            }
          }
          if (ca < zt) {
            while (ca1 >= 0) {
              if (zd + ca1 > 3) {
                obj[parseInt(zd) + parseInt(ca1) + "0"] = (this.mon(zt - ca1 + 1, zt, ca1, 1) / 2) * (this.mon((xt - zt) - ((ca - ca1)) + 1, xt - zt, ca - ca1, 1) / 2) * xl
              }
              zt1--
              ca1--
            }
          }
          arr.push(obj)
        }
      } else {
        if (ca >= zt) {
          while (zt1 >= 0) {
            //if (zd + zt1 > 3) {
            obj[parseInt(zd) + parseInt(zt1) + "1"] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((ca - zt1)) + 1, xt - zt, ca - zt1, 1) / 2)
            //  }             
            zt1--
          }
        }
        if (ca < zt) {
          while (ca1 >= 0) {
            // if (zd + ca1 > 3) {
            obj[parseInt(zd) + parseInt(ca1) + "1"] = (this.mon(zt - ca1 + 1, zt, ca1, 1) / 2) * (this.mon((xt - zt) - ((ca - ca1)) + 1, xt - zt, ca - ca1, 1) / 2)
            //}   
            zt1--
            ca1--
          }
        }
        if (xl > 1) {
          if (ca >= zt) {
            while (zt2 >= 0) {
              if (zd + zt2 > 3) {
                obj[parseInt(zd) + parseInt(zt2) + "0"] = (this.mon(zt - zt2 + 1, zt, zt2, 1) / 2) * (this.mon((xt - zt) - ((ca - zt2)) + 1, xt - zt, ca - zt2, 1) / 2) * (xl - 1)
              }
              zt2--
            }
          }

          if (ca < zt) {
            while (ca2 >= 0) {
              if (zd + ca2 > 3) {
                obj[parseInt(zd) + parseInt(ca2) + "0"] = (this.mon(zt - ca2 + 1, zt, ca2, 1) / 2) * (this.mon((xt - zt) - ((ca - ca2)) + 1, xt - zt, ca - ca2, 1) / 2) * (xl - 1)
              }
              zt2--
              ca2--
            }
          }

        }
        arr.push(obj)
      }

    } else if (wanfa == "klb") {
      if (xuan == 2) {
        if (zd == 0) {
          return 0
        } else {
          obj[xuan] = zt
        }
      } else if (xuan == 3) {
        if (zd + (xuan - xd) < 2 || zd + zt < 2) {
          return 0
        } else {
          if (kca >= zt) {
            while (zt1 >= 0) {
              if (zd + zt1 > 1) {
                obj[parseInt(zd) + parseInt(zt1)] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((kca - zt1)) + 1, xt - zt, kca - zt1, 1) / 2)
              }
              zt1--
            }
          }
          if (kca < zt) {
            while (kca1 >= 0) {
              if (zd + kca1 > 1) {
                obj[parseInt(zd) + parseInt(kca1)] = (this.mon(zt - kca1 + 1, zt, kca1, 1) / 2) * (this.mon((xt - zt) - ((kca - kca1)) + 1, xt - zt, kca - kca1, 1) / 2)
              }
              kca1--
              zt2--
            }
          }
        }
      } else if (xuan == 4) {
        if (zd + (xuan - xd) < 2 || zd + zt < 2) {
          return 0
        } else {
          if (kca >= zt) {
            while (zt1 >= 0) {
              if (zd + zt1 > 1) {
                obj[parseInt(zd) + parseInt(zt1)] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((kca - zt1)) + 1, xt - zt, kca - zt1, 1) / 2)
              }
              zt1--
            }
          }
          if (kca < zt) {
            while (kca1 >= 0) {
              if (zd + kca1 > 1) {
                obj[parseInt(zd) + parseInt(kca1)] = (this.mon(zt - kca1 + 1, zt, kca1, 1) / 2) * (this.mon((xt - zt) - ((kca - kca1)) + 1, xt - zt, kca - kca1, 1) / 2)
              }
              kca1--
              zt2--
            }
          }
        }
      } else if (xuan == 5) {
        if (zd + (xuan - xd) < 3 || zd + zt < 3) {
          return 0
        } else {
          if (kca >= zt) {
            while (zt1 >= 0) {
              if (zd + zt1 > 2) {
                obj[parseInt(zd) + parseInt(zt1)] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((kca - zt1)) + 1, xt - zt, kca - zt1, 1) / 2)
              }
              zt1--
            }
          }
          if (kca < zt) {
            while (kca1 >= 0) {
              if (zd + kca1 > 2) {
                obj[parseInt(zd) + parseInt(kca1)] = (this.mon(zt - kca1 + 1, zt, kca1, 1) / 2) * (this.mon((xt - zt) - ((kca - kca1)) + 1, xt - zt, kca - kca1, 1) / 2)
              }
              kca1--
              zt2--
            }
          }
        }
      } else if (xuan == 6) {
        if (zd + (xuan - xd) < 3 || zd + zt < 3) {
          return 0
        } else {
          if (kca >= zt) {
            while (zt1 >= 0) {
              if (zd + zt1 > 2) {
                obj[parseInt(zd) + parseInt(zt1)] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((kca - zt1)) + 1, xt - zt, kca - zt1, 1) / 2)
              }
              zt1--
            }
          }
          if (kca < zt) {
            while (kca1 >= 0) {
              if (zd + kca1 > 2) {
                obj[parseInt(zd) + parseInt(kca1)] = (this.mon(zt - kca1 + 1, zt, kca1, 1) / 2) * (this.mon((xt - zt) - ((kca - kca1)) + 1, xt - zt, kca - kca1, 1) / 2)
              }
              kca1--
              zt2--
            }
          }
        }
      } else if (xuan == 7 || xuan == 8 || xuan == 9) {
        if ((parseInt(zd) + (parseInt(xuan) - parseInt(xd)) < 4 || parseInt(zd) + parseInt(zt) < 4) && parseInt(zd) + parseInt(zt) != 0) {
          return 0
        } else {
          if (kca >= zt) {
            while (zt1 >= 0) {
              if (parseInt(zd) + zt1 > 3 || zd + zt1 == 0) {
                obj[parseInt(zd) + parseInt(zt1)] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((kca - zt1)) + 1, xt - zt, kca - zt1, 1) / 2)
              }
              zt1--
            }
          }
          if (kca < zt) {
            while (kca1 >= 0) {
              if (zd + kca1 > 3 || zd + kca1 == 0) {
                obj[parseInt(zd) + parseInt(kca1)] = (this.mon(zt - kca1 + 1, zt, kca1, 1) / 2) * (this.mon((xt - zt) - ((kca - kca1)) + 1, xt - zt, kca - kca1, 1) / 2)
              }
              kca1--
              zt2--
            }
          }
        }
      } else if (xuan == 10) {
        if ((zd + (xuan - xd) < 5 || zd + zt < 5) && zd + zt != 0) {
          return 0
        } else {
          if (kca >= zt) {
            while (zt1 >= 0) {
              if (zd + zt1 > 4 || zd + zt1 == 0) {
                obj[parseInt(zd) + parseInt(zt1)] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((kca - zt1)) + 1, xt - zt, kca - zt1, 1) / 2)
              }
              zt1--
            }
          }
          if (kca < zt) {
            while (kca1 >= 0) {
              if (zd + kca1 > 4 || zd + kca1 == 0) {
                obj[parseInt(zd) + parseInt(kca1)] = (this.mon(zt - kca1 + 1, zt, kca1, 1) / 2) * (this.mon((xt - zt) - ((kca - kca1)) + 1, xt - zt, kca - kca1, 1) / 2)
              }
              kca1--
              zt2--
            }
          }
        }
      }

      arr.push(obj)
    } else if (wanfa == "qlc") {
      if (zl == 0) {
        if (zd + zt < 4 || zd + (7 - xd) < 4) {
          return 0
        } else {
          if (qca >= zt) {
            while (zt1 >= 0) {
              if (parseInt(zd) + parseInt(zt1) > 3) {
                obj[parseInt(zd) + parseInt(zt1) + "0"] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((qca - zt1)) + 1, xt - zt, qca - zt1, 1) / 2)
              }
              zt1--
            }
          }
          if (qca < zt) {
            while (qca1 >= 0) {
              if (parseInt(zd) + parseInt(qca1) > 3) {
                obj[zd + qca1 + "0"] = (this.mon(zt - qca1 + 1, zt, qca1, 1) / 2) * (this.mon((xt - zt) - ((qca - qca1)) + 1, xt - zt, qca - qca1, 1) / 2)
              }
              qca1--
            }
          }
        }

      } else if (zl == 1) {
        if (zd + zt < 4 || zd + (7 - xd) < 4) {
          return 0
        } else {
          if (qca >= zt) {
            while (zt1 >= 0) {
              if (parseInt(zd) + parseInt(zt1) > 3) {
                obj[parseInt(zd) + parseInt(zt1) + "1"] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((qca - zt1)) + 1, xt - zt, qca - zt1, 1) / 2)
              }
              zt1--
            }
          }
          if (qca < zt) {
            while (qca1 >= 0) {
              if (parseInt(zd) + parseInt(qca1) > 3) {
                obj[parseInt(zd) + parseInt(qca1) + "1"] = (this.mon(zt - qca1 + 1, zt, qca1, 1) / 2) * (this.mon((xt - zt) - ((qca - qca1)) + 1, xt - zt, qca - qca1, 1) / 2)
              }
              qca1--
            }
          }
        }
      } else if (zl == 2) {
        if (zd + zt < 4 || zd + (7 - xd) < 4) {
          return 0
        } else {
          if (qca > zt) {
            while (zt1 >= 0) {
              if (parseInt(zd) + parseInt(zt1) > 3) {
                obj[parseInt(zd) + parseInt(zt1) + "1"] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((qca - zt1)) + 1, xt - zt - 1, qca - zt1 - 1, 1) / 2)
              }
              zt1--
            }
            while (qca1 >= 0) {
              if (parseInt(zd) + parseInt(qca1) > 3) {
                obj[parseInt(zd) + parseInt(qca1) + "0"] = (this.mon(zt - qca1 + 1, zt, qca1, 1) / 2) * (this.mon((xt - zt - 1) - ((qca - qca1)) + 1, xt - zt - 1, qca - qca1, 1) / 2)
              }
              qca1--
            }
          }
          if (q2ca <= zt) {
            while (q2ca >= 0) {
              if (parseInt(zd) + parseInt(q2ca) > 3) {
                obj[parseInt(zd) + parseInt(q2ca) + "1"] = (this.mon(zt - q2ca + 1, zt, q2ca, 1) / 2) * (this.mon((xt - zt) - ((qca - q2ca)) + 1, xt - zt - 1, qca - q2ca - 1, 1) / 2)
              }
              q2ca--
            }
            while (qca1 >= 0) {
              if (parseInt(zd) + parseInt(qca1) > 3) {
                obj[parseInt(zd) + parseInt(qca1) + "0"] = (this.mon(zt - qca1 + 1, zt, qca1, 1) / 2) * (this.mon((xt - zt - 1) - ((qca - qca1)) + 1, xt - zt - 1, qca - qca1, 1) / 2)
              }
              qca1--
            }
          }

        }
      }
      arr.push(obj)
    } else if (wanfa == "dlt") {
      if (dl == 0) {
        if (zd + (5 - xd) < 3 || zd + zt < 3) {
          return 0
        } else {
          if (dca >= zt) {
            while (zt1 >= 0) {
              if (parseInt(zd) + parseInt(zt1) > 2) {
                if (xl == 0) {
                  obj[parseInt(zd) + parseInt(zt1) + "0"] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((dca - zt1)) + 1, xt - zt, dca - zt1, 1) / 2) * (this.mon(tx - 2 + 1, tx, 2, 1) / 2)
                } else {
                  obj[parseInt(zd) + parseInt(zt1) + "0"] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((dca - zt1)) + 1, xt - zt, dca - zt1, 1) / 2) * tx
                }
              }
              zt1--
            }
          }
          if (dca < zt) {
            while (dca1 >= 0) {
              if (parseInt(zd) + parseInt(dca1) > 2) {
                if (xl == 0) {
                  obj[parseInt(zd) + parseInt(dca1) + "0"] = (this.mon(zt - dca1 + 1, zt, dca1, 1) / 2) * (this.mon((xt - zt) - ((dca - dca1)) + 1, xt - zt, dca - dca1, 1) / 2) * (this.mon(tx - 2 + 1, tx, 2, 1) / 2)
                } else {
                  obj[parseInt(zd) + parseInt(dca1) + "0"] = (this.mon(zt - dca1 + 1, zt, dca1, 1) / 2) * (this.mon((xt - zt) - ((dca - dca1)) + 1, xt - zt, dca - dca1, 1) / 2) * tx
                }
              }
              zt1--
              dca1--
            }
          }
          arr.push(obj)
        }
      } else if (dl == 1) {
        if (zd + (5 - xd) < 2 || zd + zt < 2) {
          return 0
        } else {
          if (dca >= zt) {
            while (zt1 >= 0) {
              if (xl == 0) {
                if (parseInt(zd) + parseInt(zt1) > 1) {
                  obj[parseInt(zd) + parseInt(zt1) + "1"] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((dca - zt1)) + 1, xt - zt, dca - zt1, 1) / 2) * (tx - 1)
                }
                if (parseInt(zd) + parseInt(zt1) > 2) {
                  obj[parseInt(zd) + parseInt(zt2) + "0"] = (this.mon(zt - zt2 + 1, zt, zt2, 1) / 2) * (this.mon((xt - zt) - ((dca - zt2)) + 1, xt - zt, dca - zt2, 1) / 2) * (this.mon((tx - 1) - 2 + 1, tx - 1, 2, 1) / 2)
                }
              } else {
                if (zl == 1) {
                  if (parseInt(zd) + parseInt(zt1) > 1) {
                    obj[parseInt(zd) + parseInt(zt1) + "1"] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((dca - zt1)) + 1, xt - zt, dca - zt1, 1) / 2) * tx
                  }
                } else {
                  if (parseInt(zd) + parseInt(zt1) > 1) {
                    obj[parseInt(zd) + parseInt(zt1) + "1"] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((dca - zt1)) + 1, xt - zt, dca - zt1, 1) / 2)
                  }
                  if (parseInt(zd) + parseInt(zt2) > 2) {
                    obj[parseInt(zd) + parseInt(zt2) + "0"] = (this.mon(zt - zt2 + 1, zt, zt2, 1) / 2) * (this.mon((xt - zt) - ((dca - zt2)) + 1, xt - zt, dca - zt2, 1) / 2) * (tx - 1)
                  }
                }

              }
              zt1--
              zt2--
            }
          }
          if (dca < zt) {
            while (dca1 >= 0) {
              if (xl == 0) {
                if (parseInt(zd) + parseInt(dca1) > 2) {
                  obj[parseInt(zd) + parseInt(dca1) + "0"] = (this.mon(zt - dca1 + 1, zt, dca1, 1) / 2) * (this.mon((xt - zt) - ((dca - dca1)) + 1, xt - zt, dca - dca1, 1) / 2) * (this.mon((tx - 1) - 2 + 1, tx - 1, 2, 1) / 2)
                }
                if (parseInt(zd) + parseInt(dca2) > 1) {
                  obj[parseInt(zd) + parseInt(dca2) + "1"] = (this.mon(zt - dca2 + 1, zt, dca2, 1) / 2) * (this.mon((xt - zt) - ((dca - dca2)) + 1, xt - zt, dca - dca2, 1) / 2) * (tx - 1)
                }
              } else {
                if (zl == 1) {
                  if (parseInt(zd) + parseInt(dca2) > 1) {
                    obj[parseInt(zd) + parseInt(dca2) + "1"] = (this.mon(zt - dca2 + 1, zt, dca2, 1) / 2) * (this.mon((xt - zt) - ((dca - dca2)) + 1, xt - zt, dca - dca2, 1) / 2) * tx
                  }
                } else {
                  if (parseInt(zd) + parseInt(dca1) > 2) {
                    obj[parseInt(zd) + parseInt(dca1) + "0"] = (this.mon(zt - dca1 + 1, zt, dca1, 1) / 2) * (this.mon((xt - zt) - ((dca - dca1)) + 1, xt - zt, dca - dca1, 1) / 2) * (tx - 1)
                  }
                  if (parseInt(zd) + parseInt(dca2) > 1) {
                    obj[parseInt(zd) + parseInt(dca2) + "1"] = (this.mon(zt - dca2 + 1, zt, dca2, 1) / 2) * (this.mon((xt - zt) - ((dca - dca2)) + 1, xt - zt, dca - dca2, 1) / 2)
                  }
                }
              }
              dca1--
              dca2--
            }
          }
          arr.push(obj)
        }
      } else if (dl == 2) {
        if (zd + (5 - xd) < 2 || zd + zt < 2) {
          return 0
        } else {
          if (dca >= zt) {
            while (zt1 >= 0) {
              if (xl == 0) {
                if (parseInt(zd) + parseInt(zt1) > -1) {
                  obj[parseInt(zd) + parseInt(zt1) + "2"] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((dca - zt1)) + 1, xt - zt, dca - zt1, 1) / 2)
                }
                if (parseInt(zd) + parseInt(zt3) > 1) {
                  obj[parseInt(zd) + parseInt(zt3) + "1"] = (this.mon(zt - zt3 + 1, zt, zt3, 1) / 2) * (this.mon((xt - zt) - ((dca - zt3)) + 1, xt - zt, dca - zt3, 1) / 2) * ((tx - 2) * 2)
                }
                if (parseInt(zd) + parseInt(zt1) > 2) {
                  obj[parseInt(zd) + parseInt(zt2) + "0"] = (this.mon(zt - zt2 + 1, zt, zt2, 1) / 2) * (this.mon((xt - zt) - ((dca - zt2)) + 1, xt - zt, dca - zt2, 1) / 2) * (this.mon((tx - 2) - 2 + 1, tx - 2, 2, 1) / 2)
                }
              } else {
                if (zl == 1) {
                  if (parseInt(zd) + parseInt(zt1) > -1) {
                    obj[parseInt(zd) + parseInt(zt1) + "2"] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((dca - zt1)) + 1, xt - zt, dca - zt1, 1) / 2)
                  }
                  if (parseInt(zd) + parseInt(zt2) > 1) {
                    obj[parseInt(zd) + parseInt(zt2) + "1"] = (this.mon(zt - zt2 + 1, zt, zt2, 1) / 2) * (this.mon((xt - zt) - ((dca - zt2)) + 1, xt - zt, dca - zt2, 1) / 2) * (tx - 1)
                  }
                } else {
                  if (parseInt(zd) + parseInt(zt1) > 1) {
                    obj[parseInt(zd) + parseInt(zt1) + "1"] = (this.mon(zt - zt1 + 1, zt, zt1, 1) / 2) * (this.mon((xt - zt) - ((dca - zt1)) + 1, xt - zt, dca - zt1, 1) / 2) * 2
                  }
                  if (parseInt(zd) + parseInt(zt1) > 2) {
                    obj[parseInt(zd) + parseInt(zt2) + "0"] = (this.mon(zt - zt2 + 1, zt, zt2, 1) / 2) * (this.mon((xt - zt) - ((dca - zt2)) + 1, xt - zt, dca - zt2, 1) / 2) * (this.mon((tx - 2) - 2 + 1, tx - 2, 2, 1) / 2)
                  }
                }

              }
              zt1--
              zt2--
              zt3--
            }
          }
          if (dca < zt) {
            while (dca1 >= 0) {
              if (xl == 0) {
                if (parseInt(zd) + parseInt(dca1) > 2) {
                  obj[parseInt(zd) + parseInt(dca1) + "0"] = (this.mon(zt - dca1 + 1, zt, dca1, 1) / 2) * (this.mon((xt - zt) - ((dca - dca1)) + 1, xt - zt, dca - dca1, 1) / 2) * (this.mon((tx - 2) - 2 + 1, tx - 2, 2, 1) / 2)
                }
                if (parseInt(zd) + parseInt(dca2) > 1) {
                  obj[parseInt(zd) + parseInt(dca2) + "1"] = (this.mon(zt - dca2 + 1, zt, dca2, 1) / 2) * (this.mon((xt - zt) - ((dca - dca2)) + 1, xt - zt, dca - dca2, 1) / 2) * ((tx - 2) * 2)
                }
                if (parseInt(zd) + parseInt(dca3) > -1) {
                  obj[parseInt(zd) + parseInt(dca3) + "2"] = (this.mon(zt - dca3 + 1, zt, dca3, 1) / 2) * (this.mon((xt - zt) - ((dca - dca3)) + 1, xt - zt, dca - dca3, 1) / 2)
                }
              } else {
                if (zl == 1) {
                  if (parseInt(zd) + parseInt(dca1) > -1) {
                    obj[parseInt(zd) + parseInt(dca1) + "2"] = (this.mon(zt - dca1 + 1, zt, dca1, 1) / 2) * (this.mon((xt - zt) - ((dca - dca1)) + 1, xt - zt, dca - dca1, 1) / 2)
                  }
                  if (parseInt(zd) + parseInt(dca2) > 1) {
                    obj[parseInt(zd) + parseInt(dca2) + "1"] = (this.mon(zt - dca2 + 1, zt, dca2, 1) / 2) * (this.mon((xt - zt) - ((dca - dca2)) + 1, xt - zt, dca - dca2, 1) / 2) * (tx - 1)
                  }
                } else {
                  if (parseInt(zd) + parseInt(dca1) > -1) {
                    obj[parseInt(zd) + parseInt(dca1) + "0"] = (this.mon(zt - dca1 + 1, zt, dca1, 1) / 2) * (this.mon((xt - zt) - ((dca - dca1)) + 1, xt - zt, dca - dca1, 1) / 2) * (tx - 2)
                  }
                  if (parseInt(zd) + parseInt(dca2) > 1) {
                    obj[parseInt(zd) + parseInt(dca2) + "1"] = (this.mon(zt - dca2 + 1, zt, dca2, 1) / 2) * (this.mon((xt - zt) - ((dca - dca2)) + 1, xt - zt, dca - dca2, 1) / 2) * 2
                  }
                }
              }
              dca1--
              dca2--
              dca3--
            }
          }
          arr.push(obj)
        }
      }
    }
    //console.log(arr)
    return arr
  },
  mon(min, max, wangs, langs) {
    var s = 1;
    for (var n = min - 1; n < max; n++) {
      s = s * (n + 1)
    }
    for (var n1 = wangs; n1 > 0; n1--) {
      s = s / n1
    }

    return s * langs * 2

  }

};



export default mpublic

// 数组去重
// var myArr = [1,3,4,5,6,3,7,4];
// console.log(myArr.filter((value,index,arr)=>arr.indexOf(value)===index));
// //[ 1, 3, 4, 5, 6, 7 ]